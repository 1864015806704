import styled from "styled-components"
import { device } from "../../config/devices"
import { animated } from "react-spring"

const Title = styled(animated.h1)`
  font-size: ${props => props.fontSizeSmall || "32px"};
  line-height: 0px;
  letter-spacing: -1px;
  padding-top: 20px;
  padding-bottom: 0px;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
  @media ${device.tablet} {
    font-size: ${props => props.fontSizeLarge || "92px"};
    padding-top: 30px;
  }
`

export default Title

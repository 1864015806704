import styled from "styled-components"
import { device } from "../../config/devices"
import { animated } from "react-spring"

const Text = styled(animated.p)`
  padding-top: 20px;
  font-size: 16px;
  width: 80%;
  font-family: "Open Sans", sans-serif;
  @media ${device.tablet} {
    width: 50%;
  }
`

export default Text

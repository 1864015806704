import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripLines, faTimes } from "@fortawesome/free-solid-svg-icons"
import { Button, MenuLink } from "./styles"

export const MenuIcon = ({ open, onClick }) => (
  <Button onClick={onClick}>
    {open ? (
      <FontAwesomeIcon icon={faTimes} color="#222222" size="2x" />
    ) : (
      <FontAwesomeIcon icon={faGripLines} size="2x" />
    )}
  </Button>
)

export const MenuItem = props => <MenuLink props={props} />

import React from "react"
import { NavWrapper } from "./styles"
import { MenuLink } from "../menu/styles"
import { Trail } from "react-spring/renderprops"

const items = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Uses",
    url: "/uses",
  },
]

const Nav = props => (
  <NavWrapper {...props}>
    <div style={{ padding: 40, marginTop: 40 }}>
      <Trail
        items={items}
        keys={item => item.name}
        from={{ opacity: 0, transform: "translate3d(-400px,0,0)" }}
        to={{ opacity: 1, transform: "translate3d(0,0,0)" }}
        config={{ delay: 700 }}
      >
        {item => props => (
          <MenuLink style={props} to={item.url}>
            {item.name}
          </MenuLink>
        )}
      </Trail>
    </div>
  </NavWrapper>
)

export default Nav

import styled from "styled-components"
import { animated } from "react-spring"

export const Wrapper = styled(animated.div)`
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  list-style: none;
`

export const Link = styled.a`
  padding-left: 20px;
  color: white;
  :hover {
    color: #f4b700;
  }
`

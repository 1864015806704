import styled from "styled-components"
import { animated } from "react-spring"
import { device } from "../../config/devices"

export const NavWrapper = styled(animated.div)`
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 9999;
  @media ${device.tablet} {
    width: 80%;
    height: 100%;
  }
  border: 20px solid #222222;
  background: #f4b700;
`

import styled from "styled-components"
import { device } from "../../config/devices"
import { Link } from "gatsby"

export const Button = styled.a`
  z-index: 9999;
  display: block;
  position: relative;
`
export const MenuLink = styled(Link)`
  display: block;
  font-size: 40px;
  line-height: 75px;
  letter-spacing: -1px;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
  color: #222222;

  @media ${device.tablet} {
    font-size: 92px;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  :hover {
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    padding-left: 20px;
  }
`

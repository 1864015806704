import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faMediumM,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Wrapper, Link } from "./styles"

const Social = () => {
  return (
    <Wrapper>
      <Link href="https://www.linkedin.com/in/thomaslarge/" target="_blank">
        <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
      </Link>
      <Link href="https://github.com/ThomasLarge" target="_blank">
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </Link>
      <Link href="https://medium.com/@tomlarge" target="_blank">
        <FontAwesomeIcon icon={faMediumM} size="2x" />
      </Link>
      <Link href="mailto:jobs@tomlarge.com" target="_blank">
        <FontAwesomeIcon icon={faEnvelope} size="2x" />
      </Link>
    </Wrapper>
  )
}

export default Social
